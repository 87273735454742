/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 16:14:46
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-06 09:45:55
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductCategory = params => axios({
    url: '/api/product/category/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductCategory = params => axios({
    url: '/api/product/category/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductCategory = params => axios({
    url: '/api/product/category/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductCategory = params => axios({
    url:'/api/product/category/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductCategory = params => axios({
    url: '/api/product/system/productCategory/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
